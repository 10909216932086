import { DefaultTheme } from '@nehalist/gatsby-theme-nehalem';

const Theme: DefaultTheme = {
  layout: {
    backgroundColor: '#eceff1',
    primaryColor: '#44596e',
    linkColor: '#1976d2',
  },
  breakpoints: {
    xs: '425px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1300px',
  },
  fonts: {
    base: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, '
      + 'Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
  },
  components: {
    container: {
      width: '1260px',
    },
    header: {
      height: '440px',
      background: 'linear-gradient(-45deg, #b0bec5, #44596e) repeat scroll 0 0 transparent',
    },
  },
};

export default Theme;
